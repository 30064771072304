import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import Amplify from "aws-amplify";
Amplify.configure({
  Auth: {
    userPoolId: "ap-south-1_mK51xjEdF",
    region: "ap-south-1",
    userPoolWebClientId: "7kk2mi6sc6lkfp93fg1o2destd",
  },
  API: {
    aws_project_region: "ap-south-1",
    aws_appsync_graphqlEndpoint: "https://3en7o4jpunhe3j5rbnl3e722ui.appsync-api.ap-south-1.amazonaws.com/graphql",
    aws_appsync_region: "ap-south-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  },
});
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
