import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "Login" */ "../views/MainHomePages/Login.vue"
      ),
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"
      ),
    children: [
      {
        path: "/Issuer",
        name: "Issuer",
        component: () =>
          import(
            /* webpackChunkName: "Issuer" */ "../views/ChildHomePages/Issuer.vue"
          ),
      },
      {
        path: "/NewIssuerRequests",
        name: "NewIssuerRequests",
        component: () =>
          import(
            /* webpackChunkName: "NewIssuerRequests" */ "../views/ChildHomePages/NewIssuerRequests.vue"
          ),
      },
      {
        path: "/Partners",
        name: "Partners",
        component: () =>
          import(
            /* webpackChunkName: "Partners" */ "../views/ChildHomePages/Partners.vue"
          ),
      },
      {
        path: "/TransactionLogs",
        name: "TransactionLogs",
        component: () =>
          import(
            /* webpackChunkName: "TransactionLogs" */ "../views/ChildHomePages/TransactionLogs.vue"
          ),
      },
      {
        path: "/Approvals",
        name: "Approvals",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Approvals.vue"
          ),
      },
      {
        path:'/RLApprovals',
        component:()=>import('../views/ChildHomePages/RLApprovals.vue')
      },
      {
        path: "/Templates",
        name: "Templates",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings/Templates.vue"
          ),
      },
      {
        path: "/SupportedCountries",
        name: "SupportedCountries",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings/SupportedCountries.vue"
          ),
      },
      {
        path: "/Users",
        name: "Users",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings/Users.vue"
          ),
      },
      {
        path: "/IssuerTypes",
        name: "IssuerTypes",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings/IssuerTypes.vue"
          ),
      },
      {
        path: "/Arweave",
        name: "Arweave",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../views/ChildHomePages/Settings/Arweave.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
//copy this in Jsonfiles/RouterListAdmin.json
//  {
//   "MenuName": "New Issuer Requests",
//   "MenuICon": "mdi-earth-plus",
//   "MenuRoute": "NewIssuerRequests",
//   "has_child_routes": false
// },
// {
//   "MenuName": "Templates",
//   "MenuICon": "mdi-file-pdf-box",
//   "MenuRoute": "Templates",
//   "has_child_routes": false
// },
//SuperAdmin.json
// {
//   "MenuName": "New Issuer Requests",
//   "MenuICon": "mdi-earth-plus",
//   "MenuRoute": "NewIssuerRequests",
//   "has_child_routes": false
// },
// {
//   "MenuName": "Templates",
//   "MenuICon": "mdi-file-pdf-box",
//   "MenuRoute": "Templates",
//   "has_child_routes": false
// },